<template>
  <div 
    class="slider" 
    @wheel.prevent="handleScroll" 
    @touchstart="handleTouchStart" 
    @touchmove.prevent="handleTouchMove"
    @pointerdown="handlePointerDown" 
    @pointermove="handlePointerMove" 
    @pointerup="handlePointerUp"
  >
    <div class="image-wrapper" :style="wrapperStyle">
      <img src="../public/image1.png" class="full-image" />
      <img src="../public/image2.png" class="full-image" />
      <a :href="imageLinks[3]" target="_blank"><img src="../public/image4.png" class="full-image" /></a>
      <img src="../public/image5.png" class="full-image" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const images = [
  'image1.png',
  'image2.png',
  'image4.png',
  'image5.png',
];

const imageLinks = [
  null, null, null, 'https://www.sizhiyuqing.cn', null,
];

const currentIndex = ref(0);
const scrollSpeed = 1.5; // 滑动速度
let isScrolling = false;
let startY = 0; // 记录触摸开始位置

let canScroll = 1; // 定义 flag

const handleScroll = (event) => {
  event.preventDefault();

  if (canScroll === 0) return; 

  canScroll = 0; 

  if (event.deltaY > 0) {
    if (currentIndex.value < images.length - 1) {
      currentIndex.value++;
    }
  } else {
    if (currentIndex.value > 0) {
      currentIndex.value--;
    }
  }

  setTimeout(() => {
    canScroll = 1; // 还原 
  }, 2000); 
};

const handleTouchStart = (event) => {
  startY = event.touches[0].clientY; 
  isScrolling = true; // 开始滚动
};

const handleTouchMove = (event) => {
  if (!isScrolling) return;

  const currentY = event.touches[0].clientY;
  const deltaY = startY - currentY;

  if (Math.abs(deltaY) > 30) { // 阈值，避免误触
    if (deltaY > 0) {
      if (currentIndex.value < images.length - 1) {
        currentIndex.value++;
      }
    } else {
      if (currentIndex.value > 0) {
        currentIndex.value--;
      }
    }
    isScrolling = false; // 滚动结束
  }
};

const handlePointerDown = (event) => {
  startY = event.clientY; // 记录起始位置
  isScrolling = true; // 开始滚动
};

const handlePointerMove = (event) => {
  if (!isScrolling) return;

  const currentY = event.clientY;
  const deltaY = startY - currentY;

  if (Math.abs(deltaY) > 30) { 
    if (deltaY > 0) {
      if (currentIndex.value < images.length - 1) {
        currentIndex.value++;
      }
    } else {
      if (currentIndex.value > 0) {
        currentIndex.value--;
      }
    }
    isScrolling = false; 
  }
};

const handlePointerUp = () => {
  isScrolling = false; 
};

const wrapperStyle = computed(() => {
  return {
    transform: `translateY(-${currentIndex.value * 100}vh)`, 
    transition: `transform ${scrollSpeed}s ease-in-out`,
    height: `${images.length * 100}vh`, 
  };
});
</script>

<style>
.slider {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.full-image {
  width: 100%;
  height: 100vh; 
  object-fit: cover;
  z-index: 0; 
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; 
  position: relative; 
}
</style>
